import dragon from './images/dragon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={dragon} className="App-logo" alt="logo" />
        <h1>
          Payoro Website
        </h1>
        <h3>In Development</h3>
      </header>
    </div>
  );
}

export default App;
